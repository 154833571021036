.background {
    background-image: url(https://joshphegan.com.au/img/membership_home-min.jpg);
    background-repeat: no-repeat;
    background-position-x: 80%;
    background-size: cover;

    // For medium size devices and above
    @media (min-width: 768px) {
        // background-image: none;
    }
}
