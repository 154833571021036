@tailwind base;
@tailwind components;
@tailwind utilities;
@import "react-toastify/dist/ReactToastify.css";

@import "./../node_modules/swiper/swiper.scss";
@import "./assets/scss/fonts.scss";

@import "./../node_modules/react-loading-skeleton/dist/skeleton.css";

.rdt_TableRow {
    cursor: pointer;
}

.dt-wrapper {
    path {
        // color:red;
    }
}
