.headerImage {
    min-height: 120px;
    max-height: 30%;

    // For very small devices and above
    @media (min-width: 640px) {
        min-height: 240px;
    }

    // For medium size devices and above
    @media (min-width: 768px) {
        min-height: 360px;
    }

    // For large size devices and above
    @media (min-width: 1024px) {
        min-height: 600px;
    }
}

.spacer {
    min-height: 150px;
    max-height: 30%;

    @media (max-width: 640px) {
        min-height: 320px;
    }

    // For very small devices and above
    @media (min-width: 640px) {
        min-height: 220px;
    }

    // For medium size devices and above
    @media (min-width: 768px) {
        min-height: 320px;
    }

    // For large size devices and above
    @media (min-width: 1024px) {
        min-height: 500px;
    }
}
