.sidebarItem {
    @apply text-gray-400 hover:text-white cursor-pointer transition flex gap-4 items-center text-lg tracking-wide lg:pl-6;

    border-left: 3px transparent solid;

    &:hover {
        border-left: 3px #00a2bc solid;

        svg path {
            stroke: #fff;
            transition: all;
        }
    }
}
