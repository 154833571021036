.formLabel {
    @apply pb-2 text-gray-400 text-sm uppercase;
}

.formDataDisplay {
    @apply font-bold;
}

.formEdit {
    @apply text-jp-blue cursor-pointer;
}

.formControl {
    @apply bg-transparent outline-none w-full border px-2 py-1 text-white;

    &.false {
        @apply pointer-events-none;
    }
}
