.zoomer {
    .title {
        display: none;
        transition: all 1s ease-in-out;
    }

    &:hover {
        z-index: 100;

        .title {
            display: block;
        }
    }
}

.headerImage {
    min-height: 120px;
    max-height: 30%;

    @media (max-width: 640px) {
        background-position-y: 100px !important;
    }

    // For very small devices and above
    @media (min-width: 640px) {
        min-height: 240px;
    }

    // For medium size devices and above
    @media (min-width: 768px) {
        min-height: 360px;
    }

    // For large size devices and above
    @media (min-width: 1024px) {
        min-height: 600px;
    }
}

.spacer {
    min-height: 150px;
    max-height: 30%;

    @media (max-width: 640px) {
        min-height: 400px;
    }

    // For very small devices and above
    @media (min-width: 640px) {
        min-height: 220px;
    }

    // For medium size devices and above
    @media (min-width: 768px) {
        min-height: 320px;
    }

    // For large size devices and above
    @media (min-width: 1024px) {
        min-height: 500px;
    }
}

.itemsRow {
    min-height: 100px;
    max-height: 30%;

    // For very small devices and above
    @media (min-width: 640px) {
        min-height: 140px;
    }

    // For medium size devices and above
    @media (min-width: 768px) {
        min-height: 200px;
    }

    // For large size devices and above
    @media (min-width: 1024px) {
        min-height: 300px;
    }
}

.itemInRow {
    height: 120px;
    width: 212px;

    // For very small devices and above
    @media (min-width: 640px) {
        height: 140px;
        width: 247px;
    }

    // For medium size devices and above
    @media (min-width: 768px) {
        height: 160px;
        width: 283px;
    }

    // For large size devices and above
    @media (min-width: 1024px) {
        height: 195px;
        width: 345px;
    }
}
