.listTitle {
    @apply flex gap-2 items-center cursor-pointer text-gray-400 uppercase tracking-wide;
}

.listItem {
    @apply text-gray-400 hover:text-jp-blue transition cursor-pointer;

    &.active {
        @apply text-jp-blue;
    }
}
