.grid-wrapper {
    @apply grid grid-cols-3 text-center items-center;

    div:nth-child(3n) {
        @apply text-right pr-8;
    }

    div:nth-child(3n-2) {
        @apply text-left pl-8;
    }

    div {
        @apply py-2;

        &.header-col-item {
            @apply py-4 text-xl;
            background-color: #424242;
        }
    }
}

.icons {
    @apply hover:text-jp-blue transition cursor-pointer;
}

.grid-wrapper-stats {
    @apply grid grid-cols-2 text-center items-center;

    div:nth-child(2n) {
        @apply text-right pr-8;
    }

    div:nth-child(2n-1) {
        @apply text-left pl-8;
    }

    div {
        @apply py-2;

        &.header-col-item {
            @apply py-4 text-xl;
            background-color: #424242;
        }
    }
}

.icons {
    @apply hover:text-jp-blue transition cursor-pointer;
}

.activeSelection {
    @apply bg-jp-blue text-jp-black;
}
